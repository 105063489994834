@import "~react-toastify/dist/ReactToastify.css";

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
body {
  background-color: #fbf6f2;
}

.swiper-pagination-bullet {
  background: #d9dbb3 !important;
  width: 15px !important;
  height: 15px !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
